@import 'styles/general';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: -3;
  height: calc(100vh - 13rem);
  padding: 3rem;
  font-size: 1.2em;
  img {
    width: 100%;
  }
  &_Content {
    width: 100%;
    h3 {
      text-align: center;
    }
  }
  @include tablet {
    flex-direction: row;
    img {
      width: 50%;
    }
    &_Content {
      width: 40%;
    }
  }
}
