$pr-dark-blue: #243746;
$pr-light-pink: #D5B49E;
$pr-light-green: #BBC392;
$pr-light-purple: #ac92c3;
$pr-cyan: #92c3c0;

$sec-darkish-blue: #34495E;
$sec-green: #21a75a;
$sec-pink: #cd956f;
$sec-purple: #a378c9;
$sec-blue: #1e5785;
$sec-extra-dark-blue: #141f28;

/*
GRAYS
*/
$extra-light-gray: #f6fcf9;
$extra-light-gray-border: #e5ede9;
$background-gray: #FAFAFA;
$sec-light-gray: #dddedf;
$borderGray: #ccc;
$focusGray: #c1c3c6;
$focusGrayShadow: #d3d5d9;
$tooltip-gray: #656564;
$dark-gray: #424242;



$extra-light-red: #FDA49A;
$sec-light-red: #ec573c;
// Duplicated with above??
$shadow-red: #bd5f5f;
$sec-light-blue: #1c75bd;

$red: #c23f3f;
$gray: #868a86;
$blue: #31489a;
$orange: #eead1b;
$yellow: #e1e42a;


$strong-red: #c72323;
$strong-purple: #5056ac;
$intense-blue: #235ee4;
$intense-green: #2bc71b;
$intense-yellow: #d1df2c;

/*
SIZES
*/
$home-toolbar: 7rem;
