@import 'styles/variables';
@import 'styles/general';

.Input {
  font-family: 'Arquitecta';
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
    align-items: center;
  }
}

.Label {
  display: flex;
  justify-content: flex-end;
  width: 6rem;
  max-width: 12rem;
  text-align: right;
  padding:0 2rem;
  align-self: center;
  margin: 1rem 0;
  &.LabelLong {
    width: 70%;
    max-width: none;
  }
  @include tablet {
    width: 8rem;
    align-self: auto;
    margin-top: 0;
    margin-right: 1rem;
  }
  @include desktop {
    width: 30%;
  }
}

.InputElement {
    outline: none;
    border: 2px solid $sec-light-gray;
    background-color: $sec-light-gray;
    font: inherit;
    padding: .5rem .5rem;
    display: block;
    border-radius: .3rem;
    font-size: 1.2em;
    color: $pr-dark-blue;

    @include tablet {
      margin: 1rem 0rem;
      margin-right: 1rem;
      // width: 100%;
    }

    &[multiple] {
      background: none;
      width:auto;
      min-height:18rem;
      padding: .2rem 1rem;
      border-width: 2px;
      border-style: inset;
      -moz-appearance: menulist;
      -webkit-appearance: menulist;
      appearance: menulist;
      option {
        padding: .2rem 1rem;
      }
    }
    &:focus {
      border: 2px solid $focusGray;
      background-color: white;
      box-shadow:0 0 10px $focusGrayShadow;
    }
    &:disabled {
      border: none;
      background-color: white;
    }
}

.inputBox {
  width: 100%;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @include tablet {
    width: 70%;
    padding: 0;
  }
}

.InputElementFocused {
  border: 2px solid $focusGray;
  background-color: white;
  box-shadow:0 0 10px $focusGrayShadow;
}

.Invalid {
  border: 1px solid $red;
  background-color: $extra-light-red;
}

.InvalidMessage {
  font-size: .5rem;
}

.AlertMessage {
  display: flex;
  p {
    margin-left: 1rem;
  }
}

.HelpMessage {
  display: flex;
  font-size: .9em;
  padding: 0 0 1rem 1rem;
  color: $sec-darkish-blue;
}

.svgCont {
  width: 2rem;
  enable-background:new 0 0 507.425 507.425;
}

.svgContC1 {
  fill: $intense-yellow;
}

.svgContC2 {
  fill: $extra-light-gray-border;
}

.HideMe {
  display: none;
}

.InvalidMessage {
  color: red;
  font-size: 1em
}

.SupportData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-size: .7em;
  text-align: center;
  @include tablet {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.SupportData p {
  font-size: .8em;
  padding: 0 6rem;
  font-family: 'Arquitecta';
  color: #243746;
}


.elCheckboxCont {
  width: 100%;
  display: flex;
  justify-content: center;
}


@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	 .elCheckbox {
		 --active: #235ee4;
		 --active-inner: #fff;
		 --focus: 2px rgba(39, 94, 254, .3);
		 --border: #bbc1e1;
		 --border-hover: #1e4f75;
		 --background: #fff;
		 --disabled: #f6f8ff;
		 --disabled-inner: #6a8fac;
		 -webkit-appearance: none;
		 -moz-appearance: none;
		 height: 2.3rem;
		 outline: none;
		 display: inline-block;
		 vertical-align: top;
		 position: relative;
		 margin: 0.4rem;
		 cursor: pointer;
		 border: 1px solid var(--bc, var(--border));
		 background: var(--b, var(--background));
		 transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
	}
	 .elCheckbox:after {
		 content: '';
		 display: block;
		 left: 0;
		 top: 0;
		 position: absolute;
		 transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
	}
	 .elCheckbox:checked {
		 --b: var(--active);
		 --bc: var(--active);
		 --d-o: 0.3s;
		 --d-t: 0.6s;
		 --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
	}
	 .elCheckbox:disabled {
		 --b: var(--disabled);
		 cursor: not-allowed;
		 opacity: 0.5;
	}
	 .elCheckbox:disabled:checked {
		 --b: var(--disabled-inner);
		 --bc: var(--border);
	}
	 .elCheckbox:disabled + label {
		 cursor: not-allowed;
	}
	 .elCheckbox:hover:not(:checked):not(:disabled) {
		 --bc: var(--border-hover);
	}
	 .elCheckbox:focus {
		 box-shadow: 0 0 0 var(--focus);
	}
	 .elCheckbox:not(.switch) {
		 width: 2.4rem;
	}
	 .elCheckbox:not(.switch):after {
		 opacity: var(--o, 0);
	}
	 .elCheckbox:not(.switch):checked {
		 --o: 1;
	}
	 .elCheckbox + label {
		 font-size: 14px;
		 line-height: 3.1rem;
		 display: inline-block;
		 vertical-align: top;
		 cursor: pointer;
		 margin-left: 4px;
	}
	 .elCheckbox:not(.switch) {
		 border-radius: 7px;
	}
	 .elCheckbox:not(.switch):after {
		 width: 5px;
		 height: 9px;
		 border: 2px solid var(--active-inner);
		 border-top: 0;
		 border-left: 0;
		 left: 8px;
		 top: 3px;
		 transform: rotate(var(--r, 10deg));
	}
	 .elCheckbox:not(.switch):checked {
		 --r: 43deg;
	}
	 .elCheckbox.switch {
		 width: 38px;
		 border-radius: 11px;
	}
	 .elCheckbox.switch:after {
		 left: 2px;
		 top: 2px;
		 border-radius: 50%;
		 width: 15px;
		 height: 15px;
		 background: var(--ab, var(--border));
		 transform: translateX(var(--x, 0));
	}
	 .elCheckbox.switch:checked {
		 --ab: var(--active-inner);
		 --x: 17px;
	}
	 .elCheckbox.switch:disabled:not(:checked):after {
		 opacity: 0.6;
	}
	 input[type='radio'] {
		 border-radius: 50%;
	}
	 input[type='radio']:after {
		 width: 19px;
		 height: 19px;
		 border-radius: 50%;
		 background: var(--active-inner);
		 opacity: 0;
		 transform: scale(var(--s, 0.7));
	}
	 input[type='radio']:checked {
		 --s: 0.5;
	}
}
