@import 'styles/general';

.ButtonContainer {
  display: grid;
  grid-template-columns: 20rem;
  grid-template-rows: 10rem 10rem;
  column-gap: 1rem;
  row-gap: 2rem;
  justify-items: stretch;
  align-items: stretch;
  justify-content: center;
  margin-top: 8rem;
  @include tablet {
    grid-template-columns: 20rem 20rem;
  }
}
