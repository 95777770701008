@import 'styles/general';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -3;
  height: calc(100vh - #{$home-toolbar});
  padding: 3rem;
  text-align: center;
  a {
    text-decoration: none;
    color: #BBC392;
    font-weight: bold;
    font-size: 1.1em;
    &:hover {
      text-decoration: underline;
      color: #99a268;
    }
  }
}
