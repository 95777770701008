@import 'styles/general';

.Territorio {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    font-size: 1.1em;
  }
  @include desktop {
    display: block;
    min-height: calc(100vh - #{$home-toolbar});
    position: relative;
  }

  &_content {
    padding: 4rem;
    @include desktop {
      width: 40%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    h2 {
      text-align: center;
    }
  }
  &_img {
    width: 100%;
    position: inherit;
    height: 50vh;
    @include desktop {
      position: absolute !important;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
    }
  }
}


.YomolIntro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 0;
  background-color: $background-gray;
  position: relative;
  @include tablet {
    font-size: 1.1em;
  }
  @include desktopXL {
    font-size: 1.2em;
  }
  &_content {
    padding: 4rem;
    @include desktop {
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-width: 100rem;
    }
    h3 {
      text-align: center;
    }
  }
}

.Barista {
  height: 40rem;
  @include desktop {
    height: 50rem;
  }
  @include desktop {
    height: 60rem;
  }
  @include desktopXL {
    height: 70rem;
  }
}

.Yomol {
  display: none;
  @include tablet {
    display: block;
    font-size: 1.1em;
    min-height: 70rem;
  }
  @include desktopXL {
    font-size: 1.2em;
  }
  position: relative;
  height: calc(100vh - #{$home-toolbar});
  overflow: hidden;
  &_Center {
    position: absolute;
    width: 25%;
    max-width: 40rem;
    bottom: 5rem;
    left: 37.5%;
    z-index: -1;
  }
  &_Container {
    display: flex;
    justify-content: flex-start;
    padding: 4rem;
    align-items: flex-start;
    width: auto;
    justify-content: space-around;
    &_two {
      display: flex;
      align-items: center;
      img {
        width: 50% !important;
      }
    }
  }
  &_Card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    background-color: $background-gray;
    border-radius: 2rem;
    width: 20%;
    border: 1px solid $sec-light-gray;
    margin: 0 1rem;
    max-width: 15rem;
    h4 {
      text-align: center;
    }
    p {
      font-size: .9em
    }
    cursor: pointer;
    max-width: 15rem;
    img {
      width: 100%;
    }
  }
}

.batsilMaya {
  width: auto !important;
  max-height: 6rem;
}

.YomolMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  &_Center {
    width: 80%;
    align-self: center;
    padding-top: 4rem;
  }
  &_Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 3rem;
    &> div {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
    }
    img {
      width: 70%;
      align-self: center;
      padding: 2rem;
    }
    .Three {
      display: flex;
      flex-direction: column;
      img {
        padding: 1.4rem;
        width: 70%;
      }
    }
  }
  @include tablet {
    display: none;
  }
}


.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  background-color: $background-gray;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
    font-size: 1.1em;
    &.Reverse {
      flex-direction: row-reverse;
    }
  }
  @include desktopXL {
    font-size: 1.2em;
  }
  &_content {
    padding: 4rem;
    @include tablet {
      width: 50%;
    }
  }
  img {
    width: 100%;
    max-width: 80rem;
    @include tablet {
      width: 50%;
    }
  }
  .Long {
    @include tablet {
      transform: translate(0%,-10%);
    }
  }
}
