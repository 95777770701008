.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form {
  display: grid;
  grid-template-columns: 70% 23%;
  justify-content: space-between;
  align-items: center;
}

.ResultsContainer {
  padding-top: 1rem;
  display: flex;
  align-content: center;
}
