@mixin tablet {
  @media (min-width: 48em){
    @content;
  }
}

@mixin desktop {
  @media (min-width: 62em) {
    @content;
  }
}

@mixin desktopXL {
  @media (min-width: 80em) {
    @content;
  }
}

@mixin desktopXXL {
  @media (min-width: 90em) {
    @content;
  }
}
