@import 'styles/general';

.FormsContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.TabContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.Form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  div {
    border: none;
  }
  @include tablet {
    flex-direction: row;
  }
}

.SocioName {
  font-size: 1.6em;
}

.supportButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ResultsContainer {
  margin-top: 2rem;
  padding: 3rem;
  border: 1px solid $sec-light-gray;
  border-radius: .5rem;
  border-radius: .5rem;
  background-color: $extra-light-gray;
  p {
    font-family: 'Arquitecta';
  }
}

.TableContainer {
  @extend .Container;
}
