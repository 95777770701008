@import 'styles/details';
@import 'styles/general';

.Container {
  background-color: $extra-light-gray;
  padding: 2rem;
  border-radius: .5rem;
  @include tablet {
    background-color: #f6fcf9;
    padding: 2rem;
    border-radius: .5rem;
  }
}

.ActionsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: .7rem;
  overflow-y: scroll;
  // height: 50vh;
  min-height: 40rem;

  @include tablet {
    align-items: flex-start;
    flex-direction: row;
  }
}

.ContratoContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @include tablet {
    width: 85%;
  }
}

.ContratoDetail {
  min-height: 15rem;
  height: 30vh;
  overflow-y:scroll;
  border: .1rem solid $pr-dark-blue;
  @include tablet {
    height: 40vh;
    overflow-y:scroll;
    border: .1rem solid $pr-dark-blue;
  }
}

.PaymentContainer {
  max-height: 35vh;
  overflow-y:scroll;
  border: .1rem solid $pr-dark-blue;
  background-color: white;
}

.StatusContainer {
  width: 95%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
  }
}

.StatusDataContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  p {
    margin: .4rem;
    font-size: .9em;
  }
}


.StatusDetail {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: .7em;
  div {
    margin-bottom: .3rem;
  }
}

.ButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  @include tablet {
    flex-direction: column;
  }
  button {
    flex-grow: 1;
    @include tablet {
      margin-right: 0;
      margin-left: 2rem;
    }
  }
}

.SubTitle h3,
.SubTitle h3 p {
  margin-bottom: 1rem;
  margin-left: .8rem;
}
