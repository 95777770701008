@import 'styles/general';

.SideDrawer {
  position:fixed;
  width: 280%;
  max-width: 80%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: white;
  padding: 3rem 1.5rem;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;

  @include desktop {
    display: none;
  }
  .Logo {
    height: 11%;
    margin-bottom: 3rem;
  }
  &.Open {
    transform: translateX(0);
  }

  &.Close {
    transform: translateX(-100%);
  }
}
