@import 'styles/variables';
@import 'styles/general';

.Modal {
  position: fixed;
  top: 2rem;
  z-index: 2500;
  background-color: white;
  width: 90%;
  max-width: 80rem;
  border: 1px solid $borderGray;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: .5rem;
  left: 2rem;
  max-height: 95%;
  overflow-y:scroll;
  overflow-x: auto;
  box-shadow: 0 2px 5px 0 $dark-gray;
  @include desktop {
    margin-left: calc(50% - 40rem);
  }
}
