@import 'styles/variables';

.wrap_collabsible {
  margin: 1.2rem 0;

  & input[type='checkbox'] {
    display: none;
  }
}

.lbl_toggle {
  display: block;
  font-size: 1.2rem;
  padding: 1rem;
  // color: #DDD;
  // background: #0069ff;
  cursor: pointer;
  transition: all 0.25s ease-out;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }
}


.toggle:checked+.lbl_toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsible_content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;

  & .content_inner {
    background: $extra-light-gray;
    border-bottom: 1px solid $extra-light-gray-border;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
    overflow: scroll;
    height: 28rem;
  }

  & p {
    margin-bottom: 0;
  }
}

.toggle:checked+.lbl_toggle+.collapsible_content {
  max-height: 350px;
}

.toggle:checked+.lbl_toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
