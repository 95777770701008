@import 'styles/general';

.LogoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -3;
  height: calc(100vh - #{$home-toolbar});
  justify-content: center;
  flex-direction: column;
  &Txt {
    width: 50%;
    max-width: 64rem;
    img {
      width: 100%;
      max-width: 64rem;
    }
  }
  .Image {
    margin-top: 18vh;
    width: 32%;
    height: auto;
    display: flex;
    place-content: center;
    overflow: hidden;
    background: white;
    border-radius: 30px;
    max-width: 40rem;
    // border: 1px solid $pr-dark-blue;
    .Image_svg {
      width: 56%;
      overflow: visible;
      stroke: $pr-dark-blue;
      stroke-width: 0.3;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
  }
}

.First {
  margin-top: 98vh;
  box-shadow: 2px -2px 5px 1px $borderGray;
  background-color: white;
}

.CircleBox {
  height: 50rem;
  width: 100%;
  background-color: $pr-dark-blue;
  position: relative;
  &Background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $background-gray;
    &Cnt {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40rem;
      text-align: center;
      font-size: 1.7em;
      padding: 7%;
      background-color: $background-gray;
      @include desktop {
        height: 25rem;
      }
    }
  }
}

.ParallaxPhoto {
  &Cnt{
    height: 40rem;
    @include desktop {
      height: 50rem;
    }
    @include desktopXL {
      height: 70rem;
    }
  }
}

.Intro {
  display: flex;
  min-height: 60vh;
  background-color: $pr-dark-blue;
  padding: 1%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  color: white;
  &Info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 100%;
    font-size: 1.7em;
    @include tablet {
      width: 40%;
      padding: 2rem;
    }
  }
  &Photo{
    width: 80%;
    z-index: 0;
    display: flex;
    align-items: center;
    margin: 2rem 0;
    // border: 1px solid $dark-gray;
    img {
      width: 100%;
      border-radius: .4rem;
    }
    @include tablet {
      width: 50%;
      margin: 0;
    }
  }
  &Icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    @include tablet {
      width: 40%;
      margin: 0;
    }
    &_card {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
  @include tablet {
    flex-direction: row;
    align-items: center;
  }
}

.Phrase {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7em;
  text-align: center;
  padding: 4rem;
  background-color: white;
  flex-direction: column;
  &.Back {
    background-image: url('../../../assets/photos/logoFondo.svg');
    background-position: 160% 0%;
    background-repeat: no-repeat;
  }
  &.Long {
    &> div {
      max-width: 80rem;
    }
  }
  p,
  &> div {
    max-width: 60rem;
    &.Long {
      max-width: 80rem;
    }
  }
}
