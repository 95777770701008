@import 'styles/variables';
:export { gray: $gray; }
:export { green: $sec-green; }
:export { red: $red; }
:export { yellow: $yellow; }

.MapContainer {
  height: 500px;
}

@mixin bubble($color, $txtColor) {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: $color;
  display: inline;
  padding: .2rem .4rem;
  color: $txtColor;
}

.Parr {
  margin-top: .8rem;
}

.BubVig {
  @include bubble($sec-green, white);
}

.BubVenLT30 {
  @include bubble($yellow, black);
}

.BubVen30to6M {
  @include bubble($orange, black);
}

.BubVenGT6M {
  @include bubble($red, black);
}

.Footer {
  font-size: .6em;
  display: flex;
  justify-content: space-between;
}

.region {
  &Verde {
    @include bubble(rgba(#47cb15, 0.29), black);
    border: 2px solid $sec-green;
    padding: 0 .4rem;
  }
  &Amarilla {
    @include bubble(rgba(#e1e42a, 0.29), black);
    border: 2px solid $yellow;
    padding: 0 .4rem;
  }
  &Roja {
    @include bubble(rgba(#d42e11, 0.29), black);
    border: 2px solid $red;
    padding: 0 .4rem;
  }
  &Gris {
    @include bubble(rgba(#a1a2a1, 0.29), black);
    border: 2px solid #a1a2a1;
    padding: 0 .4rem;
  }
}
