@import 'styles/variables';
@import 'styles/general';

.CardsContainer {
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: stretch;
  justify-content: space-around;
  align-content: space-around;
  @include desktop {
    grid-template-columns: 40% 60%;
  }
  &> div {
    width: 95%;
  }
}

.Inputs {
  display: flex;
  flex-direction: column;
}

.ContentContainer {
  background-color: white;
  border: .1rem solid $pr-dark-blue;
  padding: .7rem;
  overflow-y:scroll;
  height: 50vh;
  min-height: 40rem;
}

@include desktop {
  .longCard {
    grid-column-end: span 2;
  }
}
