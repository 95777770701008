@import 'styles/general';

.FormContainer {
  width: 100%;
  margin-bottom: 2rem;
}

.Form {
  display: flex;
  flex-direction: column;
  padding-left:1.5rem;
}

.Inputs {
  display: grid;
  grid-template-columns: auto;
  align-items: start;
  justify-content: center;
  max-width: 100%;
  column-gap: 3rem;
  & select {
    width: 100%;
  }
  @include desktop {
    grid-template-columns: 35% 35% 25%;
    justify-content: space-between;
  }
  &> div {
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem;
  }
}

// .InputContainer {
//   display: flex;
//   flex-direction: column;
// }

.AllGraphs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: auto;
}

.kgCurrencyToggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
  border: 1px solid $borderGray;
  border-radius: .3rem;
  padding: .3rem 4rem;
  align-self: center;
}
