@import 'styles/general';

.Content {
  /* para tener un margen, más el espacio del toolbar */
  margin-top: 7rem;
}
.PContent {
  /* para tener un margen, más el espacio del toolbar */
  width: calc(100% - .3rem);
  height: 100%;
  overflow: scroll;
  position: absolute;
  margin-left: .3rem;
  @include tablet {
    width: calc(100% - 1rem);
    margin-left: 1rem;
  }
  @include desktop {
    /* para tener un margen, más el espacio del toolbar */
    margin-left: 21rem;
    width: auto;
    overflow: auto;
    position: static;
  }
}

.LanguageSelector {
  position: fixed;
  top: 0;
  right: 20vw;
  z-index: 5000;
}
