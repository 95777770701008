@import 'styles/variables';


:export { intenseGreen: $intense-green; }
:export { secLightRed: $sec-light-red; }


.Container {
  padding: 2rem;
}

.Form {
  display: flex;
  flex-direction: column;
  button {
    align-self: center;
  }
}

.Table {
  display: flex;
  flex-direction: column;
}

.Container {
  background-color: $extra-light-gray;
  padding: 2rem;
  border-radius: .5rem;
}

.ContentContainer {
  background-color: white;
  border: .1rem solid $pr-dark-blue;
  padding: .7rem;
  overflow-y:scroll;
  height: 50vh;
  min-height: 40rem;
}
