@import 'styles/general';
@import 'styles/variables';

.CardsContainer {
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: stretch;
  &> div {
    width: 95%;
  }

  @include desktop {
    grid-template-columns: 40% 60%;
  }
}

.Inputs {
  display: flex;
  flex-direction: column;
}

@include desktop {
  .longCard {
    grid-column-end: span 2;
  }
}

.TableContainer {
  width: 100%;
  overflow-y: scroll;
  box-shadow: inset -4px 0px 3px 1px $sec-light-gray;
}
