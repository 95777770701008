@import 'styles/variables';

.Switch {
  position: relative;
  display: flex;
  width: 3.8rem;
  height: 2rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* The RoundSlider */
.RoundSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 2rem;
  &:before {
    position: absolute;
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    left: .3rem;
    bottom: .3rem;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
}

input:checked + .RoundSlider {
  background-color: #1f5179;
}

input:focus + .RoundSlider {
  box-shadow: 0 0 1px #1f5179;
}

input:checked + .RoundSlider:before {
  -webkit-transform: translateX(1.5rem);
  -ms-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}
