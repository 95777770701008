@import 'styles/variables';

.XLSButton button {
  font-family: 'Libre Baskerville';
  background-color: $pr-dark-blue;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: .8em;
  margin: 0rem 0rem;
  cursor: pointer;
  padding: .2rem 1rem;
}

.XLSButton button:hover {
  background: $sec-blue;
}
