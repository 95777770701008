@import 'styles/form';

.TabsInput {
  overflow-y:scroll;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.TabsInput ol {
  margin: 0;
}

.inValid {
  color: red;
}

.SubcuentaContainer {
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
}

.IngresoToggle {
  border: 1px solid #ccc;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IngresoToggle p {
  padding-left: 2rem;
}

.SuccessResponse {
  padding: 2rem;
}
