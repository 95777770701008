@import '../Input/Input.module';

.TextElementContainer {
  font-family: 'Arquitecta';
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin: 1.2rem 0;
}

.TextLabel {
  @extend .Label;
}

.Content {
  font: inherit;
  padding: 0;
  display: block;
  width: 100%;
  border-radius: .3rem;
  margin-right: 1rem;
  font-size: 1.3em;
  &> * {
   margin: 0;
 }
}
