.Backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1499;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: display 2s;
}
