@import 'styles/variables';

.Container {
  border-radius: .8rem;
  border: 1px solid white;
  display: inline-block;
  font-size: .7em;
  padding: .3rem;
  margin-top: .5rem;
}

.GE {
  background-color: $pr-light-pink;
}

.SO {
  background-color: $pr-light-purple;
}

.PR {
  background-color: $pr-cyan;
}

.CO {
  background-color: $pr-light-green;
}

.VI {
  background-color: $yellow;
}
