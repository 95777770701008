.Container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.innerContainer {
  display: flex;
  width: 10rem;
  height: 10rem;
}

.coffee_cup{
    width: 4rem;
    height: 4rem;
    border: 2px rgba(36, 55, 70, .1) solid;
    border-radius: 0rem 0rem 1rem 1rem;
    position: relative;
    margin: 36px auto;
    -webkit-animation: fill_color 5s linear infinite;
       -moz-animation: fill_color 5s linear infinite;
            animation: fill_color 5s linear infinite;
}
.coffee_cup:after, .coffee_cup:before{
    position: absolute;
    content: "";
}
.coffee_cup:after{
    width: 1rem;
    height: 2rem;
    border-color: rgba(36, 55, 70, 1);
    border: 2px rgba(36, 55, 70, 1) solid;
    border-left: none;
    border-radius: 0px 20px 20px 0px;
    left: 4rem;
    top: .7rem;
    -webkit-animation: border_color 5s linear infinite;
       -moz-animation: border_color 5s linear infinite;
            animation: border_color 5s linear infinite;
}
.coffee_cup:before{
    width: .15rem;
    height: .7rem;
    border-color: rgba(36, 55, 70, .1);
    background-color: rgba(36, 55, 70, 0.6);
    top: -1rem;
    left: 1.4rem;
    box-shadow: 5px 0px 0px 0px rgba(36, 55, 70, 0.6),
                5px -5px 0px 0px rgba(36, 55, 70, 0.6),
                10px 0px 0px 0px rgba(36, 55, 70, 0.6);
    -webkit-animation: steam 1s linear infinite alternate;
       -moz-animation: steam 1s linear infinite alternate;
            animation: steam 1s linear infinite alternate;
}

@-webkit-keyframes steam{
    0%{height: 0px;}
    100%{height: 6px;}
}
@-moz-keyframes steam{
    0%{height: 0px}
    100%{height: 6px;}
}
@keyframes steam{
    0%{height: 0px}
    100%{height: 6px;}
}

@-webkit-keyframes fill_color{
  0%{
    box-shadow: inset 0px 0px 0px 0px rgba(#243746,0.1);
    border-color: white;
  }
  100%{
    box-shadow: inset 0px -30px 0px 0px #243746;
    border-color: rgba(36, 55, 70, 1);
  }
}
@-moz-keyframes fill_color{
  0%{
    box-shadow: inset 0px 0px 0px 0px rgba(#243746,0.1);
    border-color: white;
  }
  100%{
    box-shadow: inset 0px -30px 0px 0px #243746;
    border-color: rgba(36, 55, 70, 1);
  }
}
@keyframes fill_color{
  0%{
    box-shadow: inset 0px 0px 0px 0px rgba(#243746,0.1);
    border-color: white;
  }
  100%{
    box-shadow: inset 0px -30px 0px 0px #243746;
    border-color: rgba(36, 55, 70, 1);
  }
}

@-webkit-keyframes border_color{
    0%{
      border-color: white;
    }
    100%{
      border-color: rgba(36, 55, 70, 1);
    }
}
@-moz-keyframes border_color{
  0%{
    border-color: white;
  }
  100%{
    border-color: rgba(36, 55, 70, 1);
  }
}
@keyframes border_color{
  0%{
    border-color: white;
  }
  100%{
    border-color: rgba(36, 55, 70, 1);
  }
}
