@import 'styles/variables';
@import 'styles/general';

.NavigationItems {
  display: flex;
  flex-flow: column;
  align-items:flex-start;
  @include tablet {
    list-style: none;
    margin: 0;
    padding: 0;
    flex-flow: row;
    align-items: center;
    height: 100%;
  }
}

.NavigationItem {
  display: flex;
  width: auto;
  padding-right: 1rem;
  /* el center es por el flex */
  align-items: center;
  font-size: 1.1em;
  a {
    height: 100%;
    padding: 2rem 0;
    text-decoration: none;
    color: $pr-dark-blue;
    &:hover {
      font-weight: bold;
    }
    @include tablet {
      padding: 0rem 1rem;
      &:hover {
        font-weight: normal;
        text-decoration: underline;
      }
      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  @include tablet {
    font-size: 1em;
  }
}

.NavigationItemSpecial {
  display: flex;
  width: auto;
  font-size: 1.1em;
  background-color: $pr-light-pink;
  border: .3rem solid $pr-light-pink;
  border-radius: .4rem;
  outline: none;
  cursor: pointer;
  font: inherit;
  margin: 1rem;
  font-weight: bold;
  a {
    padding: .8rem 2rem;
    height: 100%;
    text-decoration: none;
    color: white;
    &:hover {
      font-weight: bold;
      color: $pr-light-pink;
    }
  }
  &:hover {
    border: .3rem solid $pr-light-pink;
    background-color: white;
  }
}


.ButtonLink {
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: $pr-light-pink;
    }
  }
}


@media (max-width: 61.99em) {
    .DesktopOnly {
        display: none;
    }
}
