@import './variables';

.Container {
  background-color: white;
  padding: .7rem;
  margin: 0;
  // min-width: 50rem;
}

.SubSection {
  border-bottom: 1px solid $borderGray;
}

.StatusContainer {
  width: 5em;
  font-size: .7em;
  margin: 2rem;
}

.Title h2 {
  text-align: center;
}

.SubTitle h3 {
  margin-left: 2rem;
}

.TableGraph {
  display: flex;
  flex-direction: column;
}

@media print {
  .TableGraph {
    flex-direction: row;
  }
}
