.Backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9000;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
