@import 'styles/variables';
@import 'styles/general';

.Toolbar {
  position:fixed;
  /* width: 60vw;
  max-width: 80%;
  height: 100%;
  left: -60vw; */
  width: 21rem;
  height: 100vh;
  left: -21rem;
  top: 0;
  z-index: 2000;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  background-color: $pr-dark-blue;
  justify-content: space-between;
  padding: 0;
  // align-items: flex-start;
  @include desktop {
    width: 21rem;
    left: 0;
  }
  &.Open {
      transform: translateX(100%);
  }

  &.Close {
    transform: none;
      /* transform: translateX(-100%); */
  }

  .Border {
    position: absolute;
    top: 0;
    right: -.3rem;
    width: .3rem;
    height: 100vh;
    background-color: $pr-dark-blue;
    @include tablet {
      width: 1rem;
      right: -1rem;
    }
    @include desktop {
      display: none;
    }
  }
}

.User {
  padding: 1rem;
  text-align: right;
  a {
    color: white;
    text-decoration: none;
    font-size: .6em;
    :hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &Name a {
    font-size: 1.2em;
  }
  h3 {
    color: white;
    text-align: right;
  }
}

.DesktopOnly {
  display: flex;
  align-items: center;
}

.Logo {
  margin: 2rem;
}


.Burger {
  position: absolute;
  top: 1rem;
  right: -5.5rem;
  width: 5.5rem;
  height: 5.7rem;
  background-color: $pr-dark-blue;
  padding: 0 .8rem;
  border-radius: 0 .3rem .3rem 0;

  @include desktop {
    display: none;
  }
}

.PLogo {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: .5rem;
  @include desktop {
    padding-left: 0;
  }
}

.NavigationItemsContainer {
  max-height: calc(100vh - 27rem);
  overflow-y:scroll;
  /* to hide scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  box-shadow: inset 0 -2px 5px 0 $sec-extra-dark-blue;
}

.NavigationItemsContainer::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}
