@import 'styles/details';

.Comment {
  display: flex;
  p {
    font-family: 'Arquitecta';
  }
}

.Label {
  display: flex;
  flex-direction: column-reverse;
}

.ChatContainer {
  background-color: $pr-cyan;
  border-radius: 1rem 1rem 1rem 0;
  padding: 1rem 1.5rem;
  color: white;
  margin-bottom: 3rem;
  min-width: 40%;
  margin-left: .5rem;
  p {
    margin: auto;
  }
}


.ChatDate p {
  font-size: .7em;
  text-align: right;
  margin: 0;
}

.Checkmark {
  color: $pr-cyan;
}

.Cross {
  color: $red;
}


.ContentContainer {
  font-family: 'Arquitecta';
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin: 1.2rem 0;
}

.Label {
  display: flex;
  justify-content: flex-end;
  /* font-weight: bold; */
  width: 30%;
  max-width: 12rem;
  text-align: right;
  margin-right: 1rem;
  padding:0 2rem;
}

.Content {
  font: inherit;
  padding: 0;
  display: block;
  width: 100%;
  border-radius: .3rem;
  margin-right: 1rem;
  font-size: 1.3em;
  p {
    margin: 0;
  }
}
