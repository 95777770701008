@import 'styles/variables';

.LangDropdownList {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.LangDropdownListItem {
  display: block;
  transition-duration: 0.5s;
  color: white;
  background: $pr-dark-blue;
  float: left;
  padding: .3rem 1rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  font-size: .8em;
  &:hover > ul,
  & ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
}

.Dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
  background: $pr-dark-blue;
  list-style: none;
  margin: 0;
  padding-left: 0;
  li {
    clear: both;
    width: 100%;
    padding: 0;
    button {
      background-color: $pr-dark-blue;
      border: none;
      color: white;
      padding: 1rem 2rem;
      text-align: center;
      text-decoration: none;
      font-size: 1em;
      margin: 0rem 0rem;
      cursor: pointer;
      width: 100%;
      &:hover {
        background: $sec-blue;
      }
    }
  }
}
