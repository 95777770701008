@import 'styles/variables';

.ForecastContainer {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  justify-content: space-between;
}

.CardContainer {

  font-size: .5em;

  display: grid;
  grid-template-rows: 15% 45% 20% 10%;
  align-content: space-between;
  align-items: center;

  background-color: $extra-light-gray;
  border: 1px solid $extra-light-gray-border;
  border-radius: .2rem;
  padding: .8rem .4rem;
}

.Text {
  text-align: center;
}
