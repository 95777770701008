@import './variables';
@import './queries';

.FormContainer {
  background-color: $extra-light-gray;
  padding: 2rem;
  border-radius: .5rem;
  height: 100%;

  // Form with a block of support data
  &_extra {
    display: flex;
    flex-direction: column;

    &Info {
      &Data {
        border: .2rem dashed $pr-dark-blue;
        border-radius: .5rem;
        overflow: scroll;
        max-height: 40vh;
      }
      &> h3,
      &> h2 {
        text-align: center
      }
      @include tablet {
        width: 40%;
        &Data {
          max-height: 70vh;
        }
      }
    }

    form {
      &> h3,
      &> h2 {
        text-align: center
      }
    }

    @include tablet {
      flex-direction: row-reverse;
      form {
        width: 100%;
      }
    }
  }
}

.Inputs {
  border: 1px solid $borderGray;
  padding: 0;
  display: flex;
}

.SearchButton {
  height: 4rem;
}

.TableContainer {
  height: 60vh;
  overflow-y:scroll;
  box-shadow: inset -4px 0px 3px 1px $sec-light-gray;
  margin: 1rem;
}

.Form {
  border: .1rem solid $pr-dark-blue;
  border-radius: .5rem;
  border-radius: .8rem .8rem 0 0;
  background-color: white;
  margin: 0 .8rem;
  &_Submit {
    border: .1rem solid $pr-dark-blue;
    background-color: $pr-dark-blue;
    display: flex;
    // width: 100%;
    padding: 2rem;
    margin: 0 .8rem;
    justify-content: center;
    border-radius: 0 0 .8rem .8rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    @include tablet {
      margin: 0 1.5rem;
      margin-bottom: 2rem;
    }
  }
  @include tablet {
    margin: 0 1.5rem;
  }
}

.ModalForm {
  overflow-y:scroll;
  min-height: 60vh;
  max-height: 75vh;
}

.noScroll {
  /* overflow-y:scroll; */
}

.SupportData {
  width: 30%;
  font-family: 'Arquitecta';
  display: flex;
  justify-content: center;
  text-align: center;
  p {
    font-size: .8em;
    padding: 0 6rem;
    font-family: 'Arquitecta';
  }
}

.Center {
  text-align: center;
}

.MessageContainer {
  display: flex;
  flex-direction: column; 
}

.Message {
  padding: 3rem;
  h2, h4 {
    text-align: center;
    padding: 0 4rem;
  }
}

.SuccessResponse {
  padding: 2rem;
}
