@import 'styles/variables';
@import 'styles/general';

.HeaderContainer {
  margin-top: 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: 'Arquitecta';
    font-size: 4em;
    margin: .2em;
    text-align: center;
    @include tablet {
      margin: .2em;
      font-size: 4.2em;
    }
    @include desktop {
      font-size: 4.5em;
    }
  }

  &_main,
  &_extra {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  &_extra {
    justify-content: flex-end;
  }

  @include tablet {
    flex-direction: row;

    &_main {
      width: 70%;
    }

    &_extra {
      width: 30%;
      align-items: stretch;
    }
  }
}

.ModalTitle {
  h1 {
    font-size: 3em;
    margin: .5rem;
  }
}
