@import 'styles/form';

.InfoContainer {
  background-color: white;
}

.SubSection {
  border-bottom: 1px solid $borderGray;
}

.SubTitle h3 {
  margin-left: 2rem;
}
