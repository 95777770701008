@import 'styles/general';
@import 'styles/variables';

.Toolbar {
  height: $home-toolbar;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  z-index: 20;
  box-shadow: 0 2px 5px 1px $borderGray;
  .Logo {
    height: 100%;  /* sin este, se extiende el logo width:100%??*/
  }
}

.HambDiv {
  width: 6rem;
  padding-right: 1rem;
  @include desktop {
    display: none;
  }
}

.DesktopOnly {
  display: flex;
  align-items: center;
}

@media (max-width: 61.99em) {
  .DesktopOnly {
    display: none;
  }
}
