@import 'styles/variables';
@import 'styles/general';

.AccesoContainer {
  width: 100%;
  height: calc(100vh - #{$home-toolbar});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Acceso {
  display: flex;
  max-width: 35rem;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  box-shadow: 2px 2px 5px 1px $borderGray;
  border-radius: .5rem;
  align-items: stretch;
  text-align: center;
  @include tablet {
    padding-right: 0;
  }
  a {
    padding-left: 1rem;
    display: flex;
    text-decoration: none;
    color: $pr-dark-blue;
    font-weight: bold;
    font-size: .6em;
    &:hover {
      text-decoration: underline;
      color: $sec-blue;
    }
  }
  div {
    width: 100%;
  }
}

.ErrorMessage {
  color: red;
  font-size: .8em;
}
