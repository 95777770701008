@import 'styles/general';

.CardsContainer {
  display: grid;
  grid-template-columns: auto;
  @include tablet {
    grid-template-columns: 50% 50%;
  }
}

.Mapa {
  @include tablet {
    grid-column-end: span 2;
  }
}

.CalContainer {
  position: relative;
  padding-bottom: 100%;
}

.CalContainer iframe,
.Calendario object,
.Calendario embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
}
